@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");

 
section
{
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

section::before
{
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, #111111,transparent);
    z-index: 1000;

}

section img
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100;
    object-fit: cover;
    pointer-events: none;

}

section img#nitendo
{
    position: absolute;
    z-index: 9;
    animation: float 7s ease-in-out infinite;
}

section img#controller
{
    position: absolute;
    z-index: 10;
    animation: float 5s ease-in-out infinite;
}

#text
{
    position: absolute;
    color: #fff;
    white-space: nowrap;
    font-size: 5.3vw;
    z-index: 11;   
    top: 15vh;
    text-align: center;
    margin-top: 80px;
}

#explore
{
    text-decoration: none;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 40px;
    background: #fff;
    color: #3a0086;
    font-size: 1.5em;
    transform: translateY(150px);
    transition: 0.5s;
    z-index: 9;
    margin-bottom: 80px;
    
}

#explore:hover{
    color: rgb(0, 174, 255);
}

.sec
{
    position: relative;
    padding: 100px;
    background: linear-gradient(#111111,#3a0086);
}

.sec video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.sec h2
{
    font-size: 3.5em;
    margin-bottom: 10px;
    color: #fff;
}

.sec p
{
    font-size: 1em;
    color: #fff;
}

.container
{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.container .card
{
    position: relative;
    width: 300px;
    height: 400px;
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(84, 0, 219, 0.219),#2000354b);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.container .card .imgBx
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    transition: 0.5s ease-in-out;
}

.container .card:hover .imgBx
{
    transform: translateY(-10px);
}

.container .card .imgBx img
{
    max-width: 100%;
    margin: 0 0 20px;
    transition: 0.5s ease-in-out;
}

.container .card:hover .imgBx img
{
    transform: translate(-20px,-40px) rotate(-5deg) scale(1.4);
}

.container .card .imgBx h3
{
    color: #fff;
    font-weight: 600;
    font-size: 25px;
}

.container .card .content a
{
    position: relative;
    top:-30px;
    display: inline-block;
    padding: 12px 30px;
    background: white;
    border-radius: 40px;
    font-weight: 600px;
    letter-spacing: 1px;
    color: #000000;
    text-decoration: none;
    transition: 0.5s;
}

.container .card .content a:hover{
    color: rgb(255, 0, 140);
}
.section2
{
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(#3a0086,#000000);
    position: relative;
    padding: 100px;

    

    
}


@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-50px);
	}
	100% {
		transform: translatey(0px);
	}
}

.section2 .vrAd{
    height: 500px;
    width: 500px;
    position: relative;
    display: flex;
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

#nameVr{
    color: rgb(255, 255, 255);
    font-size: 100px;
    text-align: center;
    font-weight: 600;
    backdrop-filter: blur(100px);
}

#titleVR{
    color: #fff;
    position: relative;
    display: flex;
    font-size: 80px;
    font-weight: 500;
    z-index: 0;
    bottom: 1vh;
    right:6vw;
}

#contact-section {
  padding: 50px;
  background: linear-gradient(#111111, #3a0086);
  color: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
}


#contact-section h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-details {
  position: absolute;
  top: 160px;
  left: 50px;
  font-size: 1.2em;
}

.contact-details p {
  margin-bottom: 15px; 
  
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-right: 50px;
  padding-top: 125px;
}

.contact-form label {
  margin-bottom: 5px;
  font-size: 1em;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  color: #000 !important;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.contact-form button {
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  background-color: #ff4057;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #ff3047;
}

.popup {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4057;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 1em;
  z-index: 1000;
  /*animation: fadeOut 3s forwards;*/
}
.error {
  color: red;
  font-size: 15px;
  margin-top: 0px;
}


.map-container {
  width: 100%;
  max-width: 600px;
  height: 350px;
  margin-top: 100px;
  margin-bottom: 5px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 180px;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/*Popup css stylings*/

/*Popup container*/
.popup {
  position: fixed; /* Keeps the popup fixed on the screen */
  top: 0;
  left: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

/* Popup content box */
.popup-content {
  background-color: white;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  position: relative;
  max-width: 90%; /* Prevents it from stretching too wide */
  max-height: 90%; /* Keeps it within the screen height */
  height: 550px;
  width: 500px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Custom Close Button */
.popup-content .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  color: #ff4057; /* Red color for close button */
  transition: color 0.3s;
}

/* Close button hover effect */
.popup-content .close-btn:hover {
  color: #ff3047; /* Darker red on hover */
  
}

/* Popup image */
.popup-content img {
  width: 100%;
  height: 500px; /* Set a fixed height */
  max-width: 500px; /* Restrict image width */
  border-radius: 10px;
  margin-bottom: 2px;
}

/* Buttons container */
.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Button styling */
.popup-buttons button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  background-color: #ff4057;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

/* Button hover effect */
.popup-buttons button:hover {
  background-color: #ff3047;
}

@keyframes fadeOut {
  0% {
      opacity: 1;
  }
  90% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
