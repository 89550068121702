/* General Styles */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #111;
  color: #fff;
}

.selected {
  background-color: #ffffff;
  color: black;
}

.menu li a.active {
  background-color: #ffffff;
  color: black;
}

/* Navbar Styles */
nav {
  background-color: #00000086;
  height: 80px;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  transition: all 0.4s ease-out;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  line-height: 80px;
  height: 100%;
  width: 70px;
  padding: 10px 0;
}

nav .menu {
  margin-right: 10px;
  float: right;
}

nav .menu li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}

nav .menu li a {
  color: white;
  font-size: 17px;
  padding: 10px 20px;
  border-radius: 40px;
  font-weight: 520;
  text-transform: uppercase;
  transition: 0.4s;
}

/* Menu Hover */
nav .menu li a:hover {
  background: #ffffff;
  color: black;
}

/* Check Button for Mobile */
.checkbtn {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

/* Media Query for Mobile */
@media (max-width: 850px) {
  nav .menu {
    display: none;
  }
  .checkbtn {
    display: block;
  }
  .mobile-menu {
    display: none; /* Initially hidden */
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 90;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hamberger {
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 851px) {
  .mobile-menu {
    display: none; /* Always hidden on larger screens */
  }
  .hamberger {
    display: none;
  }
}

.hidden {
  display: none;
}

.block {
  display: flex;
}

.mobile-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 20px;
  height: 100%;
}

.mobile-menu li a {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 30px;
}

.mobile-menu li a {
  width: 100%;
  height: 40px;
  color: white;
  font-size: 17px;
  border-radius: 40px;
  font-weight: 520;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Menu Hover */
.mobile-menu li a:hover {
  background: #ffffff;
  color: black;
}

.mobile-menu .selected {
  background-color: #ffffff;
  color: black;
}
