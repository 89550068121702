@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600&display=swap");

.ftopic {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 28px;
}

.facility-description {
  padding: 1.7rem;
  text-align: justify;
  font-family: "Nunito", sans-serif;
  font-size: 1.1em;
}

#container1 {
  padding: 3rem 1rem;
  margin-top: 30px;
  font-family: "Nunito", sans-serif;
}

#container2 {
  padding: 3rem 1rem;
  margin-top: 20px;
  font-family: "Nunito", sans-serif;
}

#products-container1, #products-container2 {
  margin: auto;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 19.8px;
}

.product {
  text-align: center;
  width: 320px;
  height: 310px;
  padding: 5px;
  cursor: pointer;
  background: transparent;
  transition: transform 0.3s, outline 0.3s;
}

.product:hover {
  outline: 4px solid #FF4057;
  transform: scale(1.1);
}

.product img {
  height: 220px;
  width: 300px;
  margin-bottom: 10px;
}

.product h3 {
  font-size: var(--h3);
  color: white;
}

h2 {
  font-size: var(--h2);
  color: white;
}

.image-container {
  margin: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
}

.description {
  height: 370px;
  color: white;
  font-size: var(--des);
  padding: 20px 5px;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 100px;
  overflow-y: hidden;
}

#image {
  width: 100%;
  height: 350px;
  margin-left: 150px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .ftopic {
    font-size: 24px;
  }

  .facility-description {
    padding: 1rem;
    font-size: 1em;
  }

  #products-container1, #products-container2 {
    width: 90%;
    gap: 15px;
  }

  .product {
    width: 100%;
    height: auto;
  }

  .product img {
    height: auto;
    width: 100%;
  }

  .image-container {
    flex-direction: column;
    padding: 10px;
  }

  #image {
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .description {
    margin: 10px 0;
    padding: 10px;
    font-size: 0.9em;
    height: auto;
  }
}

@media (max-width: 480px) {
  .ftopic {
    font-size: 20px;
  }

  .facility-description {
    padding: 0.8rem;
    font-size: 0.9em;
  }

  #container1, #container2 {
    padding: 2rem 0.5rem;
  }

  #products-container1, #products-container2 {
    width: 100%;
    gap: 10px;
  }

  .product {
    padding: 3px;
  }

  .product h3 {
    font-size: 1.2em;
  }

  h2 {
    font-size: 1.5em;
  }

  .description {
    padding: 5px;
    margin: 5px 0;
    font-size: 0.8em;
  }
}
