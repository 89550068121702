@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");

#contact-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section {
  padding: 20px;  /* Reduced padding */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#contact-details {
  margin-bottom: 70px;
  align-items: flex-start;  /* Left-aligns the contact details */
  text-align: left;
  width: 100%;  /* Ensure full width */
  padding-left: 10%;  /* Adds some padding on the left to align content */
  margin-top: 140px;
}

/* #contact-details h2 {
  font-size: 3.5em;
  margin-bottom: 15px;
  color: #fff;
  text-align: left; 
} */

.contact-details p {
  margin-bottom: 5px;
  font-size: 1.2em;
}

#contact-form {
  margin-top: 0;  /* Remove gap between sections */
  margin-bottom: 0;
  width: 100%;  /* Full width to align with contact details */
  padding-left: 10%;  /* Align with the left padding of contact details */
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  text-align: start;
}

.contact-form label {
  margin-bottom: 5px;
  font-size: 1em;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  color: #000 !important;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.contact-form button {
  padding: 7px 17px;
  font-size: 1.1em;
  border: none;
  border-radius: 5px;
  background-color: #ff4057;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #ff3047;
}

.error {
  color: red;
  font-size: 15px;
  margin-top: 0px;
}

#map-section {
  margin-top: 0;
  padding-bottom: 150px;  /* Adds space between iframe and footer */
  margin-left: 100px;
  margin-right: 100px;
}

.map-container {
  width: 100%;
  max-width: 1600px;
  height: 50vh;  /* Use viewport height for map container */
  border-radius: 10px;
  overflow: hidden;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/* Responsive design for tablets and smaller screens */
@media (max-width: 768px) {
  #contact-details, #contact-form {
    padding-left: 5%; /* Reduce left padding for tablets */
  }

  #contact-details h2 {
    font-size: 2.5em; /* Reduce heading size */
  }

  .contact-details p {
    font-size: 1em; /* Adjust paragraph font size */
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9em; /* Adjust form elements' font size for tablets */

  }

  .contact-form input,
  .contact-form textarea {
    width: 100%; /* Full width for inputs on tablets */
  }

  .contact-form button {
    padding: 10px 20px; /* Adjust padding for buttons on tablets */
    align-self: center; /* Center the button */
  }

  .map-container {
    max-width: 100%;
    height: 20vh;  /* Use relative height to avoid overflow */
  }
  #map-section {
    padding-bottom: 80px; /* Adjust bottom space between the map and the footer */
  }
  footer {
    margin-top: 30px; /* Add margin to ensure footer visibility */
  }
}

@media (max-width: 576px) {
  #contact-details, #contact-form {
    padding-left: 20px; /* Minimal padding for phones */
    padding-right: 20px;
  }

  #contact-details {
    margin-bottom: 0px; /* Reduce gap between contact details and form */
  }

  #contact-details h2 {
    font-size: 2em; /* Further reduce heading size for phones */
  }

  .contact-details p {
    font-size: 0.9em; /* Smaller paragraph font size for phones */
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.7em; /* Smaller font size for form elements on phones */
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%; /* Full width, no fixed width */
  }

  .contact-form button {
    padding: 10px 15px; /* Adjust padding for button on phones */
    align-self: center; /* Center the button on phones */
    margin-left: 75px;
  }

  #map-section {
    padding-bottom: 100px; /* Adjust bottom space */
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .map-container {
    max-width: 100%; /* Full width for smaller screens */
    height: 30vh;  /* Adjust map height to relative units */
}
}
