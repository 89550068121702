

.heading {
    text-align: center;
    font-size: 2.0em;
    letter-spacing: 1px;
    padding: 40px;
    color: white;
}
.gback{
    background-image: url("../../Assests/gback.jpg");
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    
}

.gallery-image {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-image img {
    height: 250px;
    width: 350px;
    transform: scale(1.0);
    transition: transform 0.4s ease;
}

.img-box {
    box-sizing: content-box;
    margin: 10px;
    height: 250px;
    width: 350px;
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
}

.caption {
    position: absolute;
    bottom: 5px;
    left: 20px;
    opacity: 0.0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.transparent-box {
    height: 250px;
    width: 350px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
}

.img-box:hover img {
    transform: scale(1.1);
}

.img-box:hover .transparent-box {
    background-color: rgba(0, 0, 0, 0.5);
}

.img-box:hover .caption {
    transform: translateY(-20px);
    opacity: 1.0;
}

.img-box:hover {
    cursor: pointer;
}

.caption > p:nth-child(2) {
    font-size: 0.8em;
}

.opacity-low {
    opacity: 0.5;
}

.modal {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal.open {
    display: block;
}

.modal-content {
    margin: 15% auto;
    display: block;
    max-width: 95%;
    max-height: 90%;
    animation: zoomIn 0.3s;
    /* width: 50%;
    height: 50%;
    overflow-y: hidden; */
}

@keyframes zoomIn {
    from {transform: scale(0.7);}
    to {transform: scale(1);}
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}
