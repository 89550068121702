footer {
    position: relative;
    width: 100%;
    background: #7a23f2;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
}

footer .social_icon,
footer .menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

footer .social_icon li,
footer .menu li {
    list-style: none;
}

footer .social_icon li a {
    font-size: 2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

footer .social_icon li a:hover {
    transform: translateY(-10px);
}

footer .menu li a {
    font-size: 1.2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75;
}

footer .menu li a:hover {
    opacity: 1;
}

footer p {
    color: #fff;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1.1em;
}

footer .wave {
    z-index: -1;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(wave.png);
    background-size: 1000px 100px;
}

footer .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}

footer .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}

footer .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}

footer .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}

@keyframes animateWave_02 {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 1000px;
    }
}

#name {
    color: white;
    transition: 0.8s;
}

#ishan:hover,
#thushini:hover,
#gihan:hover,
#savinthie:hover {
    color: #fff;
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #f09,
        0 0 80px #f09, 0 0 90px #f09, 0 0 100px #f09, 0 0 150px #f09;
}

#additionalC {
    color: #fff;
    transition: 0.5s;
}

#additionalC:hover {
    transform: translateY(-10px);
}

/* Responsive Styles for Footer */
@media (max-width: 768px) {
    footer {
        padding: 20px 30px;
    }

    footer .social_icon li a {
        font-size: 1.8em;
        margin: 0 8px;
    }

    footer .menu li a {
        font-size: 1em;
        margin: 5px 8px;
    }

    footer p {
        font-size: 1em;
    }

    #name {
        font-size: 1.2em;
    }
}

@media (max-width: 480px) {
    footer {
        padding: 15px 20px;
    }

    footer .social_icon li a {
        font-size: 1.5em;
        margin: 5px;
    }

    footer .menu li a {
        font-size: 0.9em;
        margin: 5px;
    }

    footer p {
        font-size: 0.9em;
    }

    #name {
        font-size: 1em;
    }
}
